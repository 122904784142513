<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div>
          <b-button
            variant="outline-success text-nowrap mr-1"
            @click="openDepreciationModal()"
            ><feather-icon
              icon="TrendingDownIcon"
              size="15"
              class="text-success mr-1"
            />DEPRECIATION</b-button
          >
          <b-button
            variant="outline-success text-nowrap mr-1"
            @click="dataItemModal()"
            ><feather-icon
              icon="PlusIcon"
              size="15"
              class="text-success"
            />CREATE ITEM</b-button
          >

          <b-button
            variant="outline-info mr-1"
            @click="openModalRegisterExistentItems()"
            ><feather-icon
              icon="PlusIcon"
              size="15"
              class="text-info"
            />REGISTER EXISTENT ITEMS</b-button
          >
        </div>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'logistic-inventory-stock' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == 'logistic-inventory-stock'"
        >Current Stock</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'logistic-inventory-history' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == 'logistic-inventory-history'"
      >
        Inventory History
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="key" />
    </b-card>
    <data-item-modal
      v-if="showItemModal"
      :type-modal="'ADD'"
      @closeModal="closeModal"
    />
    <modal-register-existent-items
      v-if="showModalRegisterExistentItems"
      @hidden="closeModalRegisterExistentItems"
      @closeModal="closeModalRegisterExistentItems"
      @saveCancel="saveCancel"
    />
    <depreciation-modal
      v-if="depreciationModalOpened"
      @close="closeDepreciationModal"
    />
  </div>
</template>
<script>
import DataItemModal from "@/views/logistic/views/inventory2/modals/dataItemModal.vue";
import ModalRegisterExistentItems from "@/views/logistic/views/inventory2/modals/ModalRegisterExistentItems.vue";
import DepreciationModal from "@/views/logistic/views/inventory2/modals/DepreciationModal.vue";

export default {
  components: {
    DataItemModal,
    ModalRegisterExistentItems,
    DepreciationModal,
  },
  data() {
    return {
      showItemModal: false,
      typeModal: null,
      showModalRegisterExistentItems: false,
      depreciationModalOpened: false,
      key: 0,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    routeName() {
      this.key++
    },
  },
  methods: {
    dataItemModal() {
      this.showItemModal = true;
    },
    closeModal() {
      this.showItemModal = false;
    },
    openModalRegisterExistentItems() {
      this.showModalRegisterExistentItems = true;
    },
    saveCancel() {
      this.showModalRegisterExistentItems = false;
      this.key++
    },
    closeModalRegisterExistentItems() {
      this.showModalRegisterExistentItems = false;
      // this.key++
    },
    openDepreciationModal() {
      this.depreciationModalOpened = true;
    },
    closeDepreciationModal() {
      this.depreciationModalOpened = false;
    },
  },

};
</script>
