export default [
  {
    type: 'select',
    label: 'Category',
    margin: true,
    showLabel: true,
    options: [],
    model: null,
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    type: 'select',
    label: 'Sub-category',
    margin: true,
    showLabel: true,
    options: [],
    model: null,
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    md: 2,
    visible: false,
  },
]