<template>
  <b-modal
    ref="depreciation_by_item_type"
    :size="'xmd'"
    title="Depreciation"
    hide-footer
    no-close-on-backdrop
    scrollable
    v-model="isActive"
    @hidden="hidden"
  >
    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        @reload="$refs['refStockList'].refresh()"
      >
        <b-table
          slot="table"
          ref="refStockList"
          no-provider-filtering
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          small
          sticky-header="70vh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(total_price_in_dollars_by_equipment_type)="data">
            $ {{ data.item.total_price_in_dollars_by_equipment_type }}
          </template>
          <template #cell(total_depreciation_in_dollars_by_equipment_type)="data">
            $ {{ data.item.total_depreciation_in_dollars_by_equipment_type }}
          </template>
          <template #cell(total_current_value_in_dollars_by_equipment_type)="data">
            $ {{ data.item.total_current_value_in_dollars_by_equipment_type }}
          </template>
          <template #custom-foot>
            <b-tr>
              <b-td colspan="3"></b-td>
              <b-td class="align-middle d-flex justify-content-center">
                <div
                  class="text-border-subtotal align-middle d-flex justify-content-center"
                >
                  <span class="text-total text-light">Sub-Total</span>
                </div>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-subtotal">
                  $ {{ subtotal_price_in_dollars }}
                </span>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-subtotal">
                  $ {{ subtotal_depreciation_in_dollars }}
                </span>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-subtotal">
                  $ {{ subtotal_balance_in_dollars }}
                </span>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td colspan="3"></b-td>
              <b-td class="align-middle d-flex justify-content-center">
                <div class="text-border-total">
                  <span class="text-total text-light">Total</span>
                </div>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-total">
                  $ {{ total_price_in_dollars }}
                </span>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-total">
                  $ {{ total_depreciation_in_dollars }}
                </span>
              </b-td>

              <b-td class="text-center">
                <span class="text-value-total">
                  $ {{ total_balance_in_dollars }}
                </span>
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </filter-slot>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import Fields from "@/views/logistic/views/inventory2/data/depreciation.data.fields";
import Filters from "@/views/logistic/views/inventory2/data/depreciation.data.filters";
import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";

export default {
  components: {
    vSelect,
  },
  props: {},
  async created() {
    this.isActive = true;
    await this.getItemsCategory();
  },
  data() {
    return {
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Description...",
        model: "",
      },
      isActive: false,
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      toPage: null,

      subtotal_price_in_dollars: 0.0,
      subtotal_depreciation_in_dollars: 0.0,
      subtotal_balance_in_dollars: 0.0,

      total_price_in_dollars: 0.0,
      total_depreciation_in_dollars: 0.0,
      total_balance_in_dollars: 0.0,

      sortBy: "",
      sortDesc: true,
      totalRows: 0,
    };
  },
  computed: {
    filterCategoryValue() {
      return this.filters[0].model;
    },
  },
  mounted() {},
  methods: {
    async myProvider() {
      const params = {
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
        category_id: this.filters[0].model,
        subcategory_id: this.filters[1].model,
        search_text: this.filterPrincipal.model,
      };

      const data = await InventoryService.getDepreciationByItemType(params);
      this.startPage = data.data.from;
      this.totalRows = data.data.total;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.toPage = data.data.to;

      this.total_price_in_dollars = data.data.data[0]?.total_price_in_dollars || 0;
      this.total_depreciation_in_dollars = data.data.data[0]?.total_depreciation_in_dollars || 0;
      this.total_balance_in_dollars = data.data.data[0]?.total_current_value_in_dollars || 0;

      this.subtotal_price_in_dollars = data.data.data[0]?.subtotal_price_in_dollars || 0;
      this.subtotal_depreciation_in_dollars = data.data.data[0]?.subtotal_depreciation_in_dollars || 0;
      this.subtotal_balance_in_dollars = data.data.data[0]?.subtotal_current_value_in_dollars || 0;
      return data.data.data || [];
    },
    hidden() {
      this.isActive = false;
      this.$emit("close");
    },
    async getItemsCategory() {
      const { data } = await InventoryService.getItemsCategory();
      this.filters[0].options = data;
    },
    async getItemsSubCategory(categoryId) {
      const { data } = await InventoryService.getItemsSubcategory({
        category_id: categoryId,
      });
      this.filters[1].options = data;
    },
  },
  watch: {
    async filterCategoryValue(newCategoryId) {
      if (!!newCategoryId) {
        this.filters[1].model = null;
        await this.getItemsSubCategory(newCategoryId);
        this.filters[1].visible = true;
        return;
      }
      this.filters[1].options = [];
      this.filters[1].model = null;
      this.filters[1].visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-border-subtotal {
  background-color: #488db8;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  padding: 5px 0px;
}

.text-border-total {
  background-color: #03578b;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  padding: 5px 0px;
}

.text-value-subtotal {
  display: block;
  margin-top: 7px;
  color: #488db8;
  font-weight: bold;
}

.text-value-total {
  display: block;
  margin-top: 7px;
  color: #03578b;
  font-weight: bold;
}

.per-page-selector {
  width: 90px;
}
.per-page-datepicker {
  width: 180px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 1024) {
  .per-page-datepicker {
    width: 100px;
  }
  .column-table {
    display: flex;
    flex-direction: column;
  }
  .button-top {
    margin-bottom: 22px;
  }
}
@media (max-width: 740px) {
  .per-page-datepicker {
    width: 110px;
  }
  .button-top {
    margin-bottom: 22px;
  }
}
.b-calendar-grid-caption {
  background: transparent !important;
}
.padding {
  padding: 6px;
  width: 80%;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>